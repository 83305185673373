.pageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;

  .contentContainer {
    width: 100%;
    height: 100%;
    display: flex;
    row-gap: 40px;
    flex-direction: column;
    justify-items: start;

    &:focus-visible {
      outline: 2px solid var(--card-input-focused-border-color);
    }
  }

  &.default {
    padding: 40px;
    align-items: flex-start;
    overflow: scroll;

    .contentContainer {
      max-width: 1180px;
    }
  }
}
