@import 'src/styles/imports';

.home {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;

  @include media(S) {
    align-items: start;
  }

  .blueBean {
    position: fixed;
    top: 20%;
    right: -40%;
    width: 130%;

    @include media(L) {
      top: 120px;
      right: -20%;
      width: 100%;
    }
  }

  .homeContent {
    height: fit-content;
    max-height: calc(100% - 8rem);
    width: 100%;
    max-width: 1000px;
    display: grid;
    align-items: center;
    grid-template-columns: 55% 45%;

    @include media(S) {
      max-height: none;
      grid-template-columns: 1fr;
    }

    .bannerContainer {
      // TODO: explore if there is a cleaner way for fixing banner
      position: fixed;
      height: fit-content;
      max-height: 100%;
      width: 45%;
      max-width: 500px;
      padding: 4rem 2rem;

      @include media(S) {
        position: relative;
        height: fit-content;
        max-width: none;
        width: 100%;
        padding: 4rem 0;
      }
    }

    .categoryContainer {
      padding: 2rem;
      grid-column: 2 / span 1;

      @include media(S) {
        padding: 0;
        grid-column: 1 / span 1;
      }
    }
  }
}
