@import 'src/styles/imports';

.backNavigation {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .banner {
    display: flex;
    border-radius: 16px;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    column-gap: 80px;
    flex-direction: row;

    @include media(S) {
      flex-direction: column;
    }

    .header {
      flex: 1 1 50%;
      min-width: 200px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .heroMedia {
      min-width: 200px;
      padding: 10px;
      height: 280px;
      text-align: center;

      @include media(S) {
        width: 100%;
      }
    }
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .contentSection {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .linksSection {
      padding: 20px 0;
    }

    .links {
      list-style: none;
      padding: 0;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(
        auto-fill,
        minmax(max(200px, (100% - 3 * 20px) / 4), 1fr)
      );
      gap: 20px;
      justify-content: stretch;
      align-items: stretch;
    }
  }
}
