@import 'src/styles/imports';

.categoryGroup {
  display: flex;
  gap: 2rem;
  flex-direction: column;

  ul {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    display: grid;
    list-style: none;
    padding: 0;
    margin: 0;

    @include media(S) {
      gap: 1rem;
    }
  }
}
