@import 'src/styles/imports';

.loading {
  display: flex;
  justify-content: center;
}

.links {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(240px, (100% - 3 * 20px) / 4), 1fr)
  );
  gap: 20px;
  justify-content: stretch;
  align-items: stretch;

  &:focus-visible {
    outline-offset: 4px;
    border-radius: 12px;
  }
}

.faq {
  margin: 20px 0;
}

.searchResult {
  outline: none;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;

    .filters {
      display: flex;
      flex-direction: row;
      gap: 20px;
    }
  }

  .bubbleFilter {
    margin-bottom: 20px;
  }
}
