.contentContainer {
  margin-top: 20px;

  .contentHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .links {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(max(200px, (100% - 3 * 20px) / 4), 1fr)
    );
    gap: 20px;
    justify-content: stretch;
    align-items: stretch;
  }
}

[role='tabpanel']:focus-visible {
  border-radius: 2px;
  outline: 2px solid var(--card-input-focused-border-color);
}
