@import 'src/styles/imports';

.categoryCard {
  height: 110px;
  background-color: var(--card-bg-color);
  border: solid 1px var(--card-border-color);
  border-radius: 16px;
  color: var(--card-text-color);

  a {
    display: block;

    &:focus-visible {
      border-radius: 16px;
      outline: 2px solid var(--card-input-focused-border-color);
      outline-offset: -2px;
    }
  }

  .category {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 1rem;
    gap: 1rem;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      text-align: center;
      text-overflow: ellipsis;
      font-weight: 500;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
