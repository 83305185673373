@import 'src/styles/imports';

.entity {
  height: 100%;

  a:link,
  a:visited {
    text-decoration: none;
    color: var(--card-text-color);
  }

  .fakeLink {
    transition: all ease-in-out 0.3s;
    height: 250px;
    padding: 20px;
    background-color: var(--card-bg-color);
    border: 1px solid var(--card-border-color);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .icon {
      background-position: 0 0;
      border-radius: 16px;
      width: 28px;
      height: 28px;
      background-size: 400%;
      background-image: linear-gradient(
        -45deg,
        #b6b6b6 0%,
        #918e8e 20%,
        #b6b6b6 100%
      );
      animation: Gradient 4s ease infinite;
    }

    .name {
      background-position: 0 0;
      border-radius: 16px;
      width: 40%;
      height: 12px;
      background-size: 400%;
      background-image: linear-gradient(
        -45deg,
        #c6c6c6 0%,
        #a9a9a9 20%,
        #c6c6c6 100%
      );
      animation: Gradient 4s ease infinite;
    }

    .description {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .bar {
        background-position: 0 0;
        border-radius: 16px;
        width: 100%;
        height: 10px;
        background-size: 400%;
        background-image: linear-gradient(
          -45deg,
          #e8e8e8 0%,
          #cbcbcb 20%,
          #e8e8e8 100%
        );
        animation: Gradient 4s ease infinite;
      }
    }
  }

  .link {
    transition: all ease-in-out 0.3s;
    height: 100%;
    padding: 20px;
    background-color: var(--card-bg-color);
    border: 1px solid var(--card-border-color);
    border-bottom: 2px solid var(--card-border-color);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    h3 {
      letter-spacing: -0.2px;
      line-height: 120%;
    }

    p {
      color: var(--card-text-secondary-color);
      line-height: 150%;
    }

    .linkHeader {
      display: flex;
      justify-content: space-between;
      align-content: center;

      .externalIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all ease-in-out 0.3s;
        opacity: 0;
      }
    }

    &:hover {
      cursor: pointer;
      box-shadow: $shadow-lg;
      transform: translateY(-10px);

      .linkHeader {
        .externalIcon {
          opacity: 1;
        }
      }
    }

    &:focus-visible {
      outline: 2px solid var(--card-input-focused-border-color);
      outline-offset: -2px;
      cursor: pointer;
      box-shadow: $shadow-lg;
      transform: translateY(-10px);

      .linkHeader {
        .externalIcon {
          opacity: 1;
        }
      }
    }
  }
}

@keyframes Gradient {
  0% {
    background-position: 100% 100%;
  }

  100% {
    background-position: -100% -100%;
  }
}
