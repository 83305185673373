@import 'src/styles/imports';

.banner {
  height: 100%;
  width: 100%;
  display: flex;
  row-gap: 20px;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    opacity: 0;
    animation: fadeIn ease-in-out 0.3s forwards 1s;

    @media (prefers-reduced-motion) {
      animation: fadeIn 0.5s forwards 0s;
    }

    h1 {
      line-height: 1.25;
      font-size: clamp(2.8rem, 2vw, 3.8rem);
    }
  }
}
