@import 'src/styles/imports';

.categories {
  animation: fadeIn ease-in-out 0.5s forwards;
  display: flex;
  gap: 4rem;
  flex-direction: column;

  @media (prefers-reduced-motion) {
    animation: fadeIn 0.5s forwards 0s;
  }
}
